<template>
    <div class="flex min-h-screen flex-col items-center pt-6 sm:justify-center sm:pt-0">
        <div>
            <slot name="logo" />
        </div>

        <div
            v-if="!hasLeft"
            class="mt-6 max-w-2xl bg-white px-10 py-10 shadow-md sm:rounded-lg">
            <slot name="logo-inner" />

            <slot />
        </div>
        <div
            v-else
            class="mt-6 grid max-w-7xl grid-cols-2 gap-8 bg-white px-6 py-6 shadow-md sm:rounded-lg">
            <div class="col-span-2">
                <slot name="logo-inner" />
            </div>

            <div class="min-h-full w-full border-r border-gray-200">
                <slot name="left" />
            </div>
            <div class="min-h-full w-full">
                <slot />
            </div>
        </div>
    </div>
</template>
<script setup lang="ts">
import { computed, useSlots } from "vue"

const hasLeft = computed(() => !!useSlots().left)
</script>
